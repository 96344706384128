@import 'bootstrap/dist/css/bootstrap.min.css';

:root {
  --fz: 16px;
  --theme--contrast-0: #121212;
  --theme-body-bg: #f5f5f5;
  --theme-body-txt: #000000;
  --search-size-mx-h: 3rem;
  --zIndex-header: 20;
  --zIndex-component: 5;
  --brand-color_1: #00ff99;
  --brand-color_2: #00ccff;
  --brand-color_3: #9933ff;
  --brand-color_4: tomato;
  --brand-color_5: #fff35c;
  --brand-color_6: #8429de;
}

html {
  box-sizing: border-box;
  font-size: 1rem;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  /* font-family: 'Manrope', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--fz);
  background-color: var(--theme-body-bg);
  color: var(--theme-body-txt);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* me influye en el tamaño de las letras de los submenús de la barra de menús*/
a {
  color: #09f;
  /* font-size: 32px; */
  text-decoration: none; /* quito el subrachado de lso enlaces (anchor-ancla)*/
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


/* -------------------- rf-layout  - Component / header */
.o-header {
  background-color: inherit;
  position: sticky;
  top: 0;
  z-index: var(--zIndex-header);
  max-height: var(--search-size-mx-h);
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
  padding: 30px;
}

.App-content {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#main-container {
  place-items: center center;
  padding: 10px;
}

#sign-in-form {
  max-width: 330px;
}
